import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import Tabs from 'react-responsive-tabs'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { Button, Col, Row } from 'reactstrap'

import Tab from './tab'
import { checkPermission, cms, editCMS, tidyName } from '../helper'
import { setDomain, setDropdownOptionsByPage, submitRequest } from '../Redux/Reducers/CMS'
import Icon from '../CMSComponent/icon'
import ConfirmButton from './Common/confirmButton'
import { removeItemByName } from '../Redux/Reducers/CMS'
import EditTabPage from './EditCMS/ContentForms/editTabPage'
import IconSet from '../CMSComponent/icon'
import SetTabOrder from './EditCMS/ContentForms/setTabOrder'
import AddGridData from './Grid/Component/AddGridData/addGridData'
import AddNewTabOnPage from './EditCMS/ContentForms/addTabHere'
import ApproveJobsQuestionnaire from '../Components/PageSpecificComponents/ApproveJobs/approveJobsQuestionnaire'
import SecurityTab from '../Pages/UserSecurity/securityTab'
import Permission from '../Pages/Permission/permission'
import PageTitle from '../Layout/AppMain/pageTitle'
import UserPermission from '../Pages/Permission/UserPermission/userPermission'
import SelectDomainField from './Common/selectDomain'
import RCTabs from '../Components/Tabs/rcTabs'
import MailBox from '../Components/PageSpecificComponents/MailBox/v2/mailBoxv2'
import NoDataImage from './Common/NoData/noDataImage'

// import 'react-responsive-tabs/styles.css'

const securityTabs = ['security_messages', 'security_contractors', 'security_templates', 'security_items', 'security_domains']

const checkDefaultShowAllTabs = (pageName) => {
  if (pageName === 'send_reports') {
    return false
  } else if (pageName?.includes('security')) {
    return false
  } else if (pageName === 'users') {
    return false
  } else {
    return true
  }
}

const GetTabTitle = ({ progressData, tab }) => {
  if (tab?.is_grid) {
    if (tab?.show_data_progress && false) {
      if (progressData?.[tab?.name]?.count_int > 0) {
        return (
          <div onClick={() => editCMS(tab.cms_tab_key)}>
            {cms(tab.cms_tab_key)}{' '}
            <span className='bg-success ml-2' style={{ padding: '1px 10px', borderRadius: '20px' }}>
              <i class='fa-solid fa-check '></i>
            </span>
          </div>
        )
      } else {
        return (
          <div onClick={() => editCMS(tab.cms_tab_key)}>
            {cms(tab.cms_tab_key)}{' '}
            <span className='bg-danger ml-2' style={{ padding: '1px 10px', borderRadius: '20px' }}>
              <i class='fa-solid fa-xmark '></i>
            </span>
          </div>
        )
      }
    } else if (tab?.show_count && progressData?.[tab?.name]?.count_int !== undefined) {
      return (
        <div onClick={() => editCMS(tab.cms_tab_key)}>
          {cms(tab.cms_tab_key)}{' '}
          <span className='bg-info ml-2' style={{ padding: '1px 10px', borderRadius: '20px', color: '#fff' }}>
            {progressData?.[tab?.name]?.count_int}
          </span>
        </div>
      )
    } else {
      return <div onClick={() => editCMS(tab.cms_tab_key)}>{cms(tab.cms_tab_key)}</div>
    }
  } else {
    if (tab?.show_data_progress) {
      if (progressData?.[tab?.name]?.count_int > 0) {
        return (
          <div onClick={() => editCMS(tab.cms_tab_key)}>
            {cms(tab.cms_tab_key)}{' '}
            <span className='bg-success ml-2' style={{ padding: '1px 10px', borderRadius: '20px' }}>
              <i class='fa-solid fa-check '></i>
            </span>
          </div>
        )
      } else {
        return (
          <div onClick={() => editCMS(tab.cms_tab_key)}>
            {cms(tab.cms_tab_key)}{' '}
            <span className='bg-danger ml-2' style={{ padding: '1px 10px', borderRadius: '20px' }}>
              <i class='fa-solid fa-xmark '></i>
            </span>
          </div>
        )
      }
    } else {
      return <div onClick={() => editCMS(tab.cms_tab_key)}>{cms(tab.cms_tab_key)}</div>
    }
  }
  return (
    <div>
      {cms(tab.cms_tab_key)}{' '}
      <span className='bg-success ml-2' style={{ padding: '1px 10px', borderRadius: '20px' }}>
        <i class='fa-solid fa-check '></i>
      </span>
    </div>
  )
  if (
    progressData &&
    progressData[tab?.name] &&
    progressData[tab?.name]?.count &&
    !progressData[tab?.name]?.count?.includes('undefined') &&
    !tab?.show_count
  ) {
    return tidyName(cms(tab.cms_tab_key)) + ' ' + `${progressData[tab?.name]?.count}`
  } else {
    return tidyName(cms(tab.cms_tab_key))
  }
}

const getTabClassname = (progressData, tab) => {
  if (progressData && progressData[tab?.name] && progressData[tab?.name]?.class && !tab?.show_count) {
    return progressData[tab?.name]?.class
  } else {
    return ''
  }
}

const select = (state) => ({
  editMode: state.CMS.editMode,
  coreData: state.CMS.coreData,
  currentTab: state.Page.currentTab,
  editPageMode: state.CMS.editPageMode,
  selectedDomainId: state.CMS.selected_domain_id,
  domain_id: state.CMS.selected_domain_id,
  userSecurityOverviewDropdown: state.CMS.userSecurityOverviewDropdown,
})

const CMSTabs = ({
  content,
  editMode,
  coreData,
  currentTab,
  pageSize,
  pageName,
  fullPage,
  isModel,
  gridData,
  editPageMode,
  isModalAction,
  addDataDetail,
  setAddDataDetail,
  selectedDomainId,
  domain_id,
  userSecurityOverviewDropdown,
  pageData,
}) => {
  const [tabs, updateTabs] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const page = useSelector((state) => state.CMS.coreData.page)
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const parentTableDataRedux = useSelector((state) => state.CMS.parentTableData)
  const progressData = useSelector((state) => state.CMS.progressData)
  const dispatch = useDispatch()

  const getTabs = () => {
    return tabs.map((tab, index) => {
      return {
        // title: 'Jasmin',
        title: <GetTabTitle progressData={progressData} tab={tab} />,
        cmsTabKey: tab.cms_tab_key || index,
        tabClassName: getTabClassname(progressData, tab),
        getContent: () => {
          if (addDataDetail?.activeTabNumber === index && addDataDetail?.open) {
            return (
              <AddGridData
                addDataDetail={addDataDetail}
                setAddGridDataDetail={setAddDataDetail}
                hideTitle={true}
                clearCurrentRowOnBack={false}
                key={index}
              />
            )
          }

          if (
            tab.cms_tab_key === 'nav.all_questions_and_answers' ||
            tab.cms_tab_key === 'nav.all_question_answers' ||
            tab?.method_name === 'all_questions_answers'
          ) {
            return <ApproveJobsQuestionnaire key={index} />
          }
          if (tab?.source_table === 'permissions' && pageName === 'users') {
            return <UserPermission key={index} />
          }
          if (tab?.source_table?.includes('security_')) {
            return (
              <SecurityTab
                noTitle={tab.component_name === 'show_tabs_on_load'}
                hideButtons={tab.component_name === 'show_tabs_on_load'}
                content={tab.component_call_form}
                tabName={tab.name}
                sourceTable={tab.source_table}
                pageName={tab.name}
                parentPageName={pageName}
                fullPage={fullPage}
                isDatabaseDropdownVisible={fullPage}
                isModel={isModel}
                gridData={gridData}
                hideEditIcon={!isModel}
                showMore={false}
                cmsTabKey={tab.cms_tab_key}
                currentTabData={tab}
                addDataDetail={addDataDetail}
                setAddDataDetail={setAddDataDetail}
                activeTabNumber={activeTab}
                isTab={true}
                key={index}
              />
            )
          }
          if (tab?.name === 'schedules_messages' || tab?.name === 'chat_messages') {
            return <MailBox page={tab}></MailBox>
          }
            return (
              <Tab
                noTitle={tab.component_name === 'show_tabs_on_load'}
                hideButtons={tab.component_name === 'show_tabs_on_load'}
                content={tab.component_call_form}
                tabName={tab.name}
                sourceTable={tab.source_table}
                pageName={tab.name}
                parentPageName={pageName}
                fullPage={fullPage}
                isDatabaseDropdownVisible={fullPage}
                isModel={isModel}
                gridData={gridData}
                hideEditIcon={!isModel}
                showMore={false}
                cmsTabKey={tab.cms_tab_key}
                currentTabData={tab}
                addDataDetail={addDataDetail}
                setAddDataDetail={setAddDataDetail}
                activeTabNumber={activeTab}
                isTab={true}
                key={index}
                pageData={pageData}
              />
            )
        },
        disabled: !tab.allowed,
        key: index,
      }
    })
  }

  const handleTabEditCMS = (e) => {
    editCMS(getTabs()[e.nextTabKey].cmsTabKey)
    if (editMode) return false
  }

  const getPayloadForGrid = (mainGrid) => {
    const column = mainGrid?.component_call_cols
      ?.filter((item) => item.component_name === 'text_data_col')
      ?.map((item) => ({
        field: item?.field_id,
        name: cms(item?.cms_key),
      }))
    return column
  }

  const getPayloadForForm = (fields) => {
    let field = []
    fields?.map((item) => {
      field.push({
        field: item?.field_id,
        name: cms(item?.cms_key),
      })
    })
    return field
  }

  const getGridPayload = (forms) => {
    let columns = []
    forms?.map((form) => {
      const cols = getPayloadForGrid(form?.main_grid)

      columns = [...cols, ...columns]
    })
    return columns
  }

  const getFormPayload = (forms) => {
    let all_fields = []
    forms?.map((form) => {
      const fields = getPayloadForForm(form?.component_call_fields)

      if (fields?.length > 0) {
        all_fields = [...all_fields, ...fields]
      }
    })

    return all_fields
  }

  const onPrintClick = () => {
    let payload = []
    const page_data = page[pageName]
    const main_table = page_data?.component_call_buttons?.filter((item) => item.component_name === 'hidden_tab')

    const child_table = page_data?.component_call_buttons?.filter((item) => item.component_name === 'show_tabs_on_load')
    // child_table?.map((tab) => {
    //   payload[tab?.source_table] = []
    // })
    child_table?.map((tab) => {
      if (tab?.is_grid === 1) {
        payload.push({
          source_table: tab.source_table,
          name: cms(tab.cms_tab_key),
          columns: [...getGridPayload(tab?.component_call_form)],
        })
      } else {
        payload.push({
          source_table: tab.source_table,
          name: cms(tab.cms_tab_key),
          columns: [...getFormPayload(tab.component_call_form)],
        })
      }
    })
    const final_payload = {
      tabs: {
        ...payload,
      },
      main_source_table: main_table[0]?.source_table,
      main_source_id: currentRow[currentRow?.length - 1]?.id,
      cms_key: page_data?.cms_header_name,
    }

    dispatch(
      submitRequest({
        url: 'connect-template-print',
        data: final_payload,
        scheduleApiCall: true,
        noReload: true,
        load: true,
      })
    )
  }

  useEffect(() => {
    if (checkDefaultShowAllTabs(pageName)) {
      const filtered_tab_on_permission = content?.filter((tab) => {
        if (checkPermission('view', tab?.name, null, true)) {
          return true
        } else {
          return false
        }
      })

      if (isModel) {
        updateTabs(content)
      } else {
        updateTabs(filtered_tab_on_permission)
      }
    }
  }, [content, coreData])

  useEffect(() => {
    const currentUserSecurityData = userSecurityOverviewDropdown?.[currentRow?.[currentRow?.length - 1]?.id]

    if (currentUserSecurityData && content?.length > 0 && pageName?.includes('security')) {
      const grantAllFields = []
      Object.keys(currentUserSecurityData)?.map((key) => {
        if (currentUserSecurityData[key] === 'grant_all') {
          grantAllFields?.push(key)
        }
        if (grantAllFields?.length > 0) {
          const filtered_tabs = content?.filter((tab) => {
            if (tab?.source_table === 'users') {
              return true
            }
            if (!grantAllFields?.includes(tab?.source_table?.replace('security_', '')) || tab?.source_table === 'users') {
              return true
            } else {
              return false
            }
          })

          updateTabs(filtered_tabs)
        } else if (pageName?.includes('security')) {
          updateTabs(content)
        }
      })
    } else if (pageName === 'send_reports') {
      if (currentRow[currentRow?.length - 1]?.approved || currentRow[currentRow?.length - 1]?.auto_approve) {
        updateTabs(content)
      } else {
        updateTabs(content?.slice(0, 2))
      }
    } else if (pageName === 'users') {
      // user_groups
      if (currentRow[currentRow?.length - 1]?.tasks) {
        updateTabs(content)
      } else {
        const filtered_content = content?.filter((tab) => tab?.source_table !== 'user_groups')
        updateTabs(filtered_content)
      }
    } else {
      updateTabs(content)
    }
  }, [userSecurityOverviewDropdown, content, currentRow])

  const activeTabData = tabs[activeTab]
  const page_data = page?.[pageName]
  const main_table = page_data?.component_call_buttons?.filter((item) => item.component_name === 'hidden_tab')

  if (tabs?.length === 0) return <NoDataImage></NoDataImage>

  //if only single tab, tab name not visible so removed this condition
  if (tabs.length === 1 || (tabs?.length === 1 && isModel))
    return (
      <Row>
        {!isModel && tabs[0]?.name !== 'draw' && (
          <Col md={12}>
            <PageTitle
              onTitleClick={() => editCMS(tabs?.[0]?.cms_tab_key || tabs?.[0]?.cms_tab_key)}
              heading={cms(tabs?.[0]?.cms_tab_key || tabs?.[0]?.cms_tab_key)}
              icon={tabs?.[0]?.icon}
              component_name={tabs?.[0]?.component_name}
              hideBreadCrumbs
            />
          </Col>
        )}

        {editPageMode && !isModel && (
          <div>
            <SetTabOrder
              tabs={tabs}
              pageName={pageName}
              formName={pageName}
              main_source_table={
                parentTableDataRedux && parentTableDataRedux?.length > 0
                  ? parentTableDataRedux[parentTableDataRedux?.length - 1]?.source_table
                  : main_table && main_table[0]?.source_table
              }
            />
          </div>
        )}

        <div style={{ marginLeft: 'auto' }}>
          {editPageMode && (
            <>
              <AddNewTabOnPage page={page_data} parentPageName={pageName} />
              <span className='verticle-devider'></span>

              <EditTabPage activeTabData={tabs[0]} pageName={tabs[0]?.name} parentPageName={pageName} showTitle gridData={gridData} />
            </>
          )}
          {tabs[0]?.component_call_form?.length === 1 &&
            tabs[0]?.component_call_form?.map((form, key) => {
              return (
                <React.Fragment key={key}>
                  {editPageMode && tabs[0]?.component_name === 'show_tabs_on_load' && (
                    <span className='no-print'>
                      <ConfirmButton
                        color='danger'
                        id={form.name}
                        clickEvent={() =>
                          dispatch(
                            removeItemByName({
                              name: form.name,
                              type: 'form',
                            })
                          )
                        }>
                        <Icon icon={'fas fa-trash'} color='light' /> Remove {cms(form.cms_header_key) || form.name} ({form.component_name})
                      </ConfirmButton>
                    </span>
                  )}
                </React.Fragment>
              )
            })}
        </div>

        <Col md={12}>
          {tabs[0]?.source_table === 'permissions' && (main_table?.[0]?.source_table === 'roles' || !main_table?.[0]?.source_table) ? (
            <Permission parent_source_table={main_table?.[0]?.source_table} />
          ) : (
            <Tab
              noTitle={tabs[0].component_name === 'show_tabs_on_load'}
              hideButtons={tabs[0].component_name === 'show_tabs_on_load'}
              content={tabs[0].component_call_form}
              tabName={tabs[0].name}
              sourceTable={tabs[0].source_table}
              pageName={tabs[0].name}
              parentPageName={pageName}
              fullPage={fullPage}
              isDatabaseDropdownVisible={fullPage}
              isModel={isModel}
              gridData={gridData}
              isModalAction={isModalAction}
              currentTabData={tabs[0]}
              addDataDetail={addDataDetail}
              setAddDataDetail={setAddDataDetail}
              activeTabNumber={0}
              isTab={true}
            />
          )}
        </Col>
      </Row>
    )

  const tab_items = getTabs()

  return (
    <>
      {fullPage && (
        <Row>
          <AvForm>
            {!isModel && (
              <Row>
                <AvForm>
                  <Col md={12}>
                    <SelectDomainField />
                  </Col>
                </AvForm>
              </Row>
            )}
          </AvForm>
          <div className='no-print'>
            <Button
              // className={`edit-form-page-button `}
              color='warning'
              onClick={onPrintClick}
              style={{ marginTop: '2rem', marginLeft: '1rem', color: '#fff' }}>
              <IconSet icon='fa-regular fa-print' color='light' style={{ marginRight: '0.4rem' }} /> Print All Tabs
            </Button>
          </div>
          {editPageMode && (
            <div>
              <SetTabOrder
                tabs={tabs}
                pageName={pageName}
                parentPageName={pageName}
                formName={pageName}
                main_source_table={
                  parentTableDataRedux && parentTableDataRedux?.length > 0
                    ? parentTableDataRedux[parentTableDataRedux?.length - 1]?.source_table
                    : main_table && main_table[0]?.source_table
                }
              />
            </div>
          )}

          <div style={{ marginLeft: 'auto' }}>
            {editPageMode && <EditTabPage activeTabData={activeTabData} pageName={activeTabData?.name} parentPageName={pageName} />}
            {activeTabData?.component_call_form?.length === 1 &&
              activeTabData?.component_call_form?.map((form, key) => {
                if (
                  (checkPermission('delete_form') && activeTabData?.is_grid !== 1) ||
                  (checkPermission('delete_grid') && activeTabData?.is_grid === 1)
                ) {
                  return
                }
                return (
                  <>
                    {editPageMode && activeTabData?.component_name === 'show_tabs_on_load' && (
                      <span className='no-print'>
                        <ConfirmButton
                          color='danger'
                          id={form.name}
                          clickEvent={() =>
                            dispatch(
                              removeItemByName({
                                name: form.name,
                                type: 'form',
                              })
                            )
                          }>
                          <Icon icon={'fas fa-trash'} color='light' /> Remove {cms(form.cms_header_key) || form.name} ({form.component_name})
                        </ConfirmButton>
                      </span>
                    )}
                  </>
                )
              })}
          </div>
        </Row>
      )}

      <RCTabs
        items={tab_items}
        selectedTabKey={currentTab || 0}
        activeTab={activeTab}
        onChange={(e) => {
          setActiveTab(parseInt(e))
        }}
        pageData={pageData}
      />
      {/* <Tabs
        selectedTabKey={currentTab || 0}
        tabsWrapperClass={'body-tabs body-tabs-alt edit-page-tab ' + pageSize}
        transform={false}
        showInkBar={true}
        items={getTabs()}
        beforeChange={(e) => handleTabEditCMS(e)}
        onChange={(e) => setActiveTab(e)}
        showMoreIcon={true}
        allowRemove={false}
        // showMoreLabel='More tabs'
      /> */}
    </>
  )
}

export default connect(select)(CMSTabs)
